<style scoped lang="less">
  .title {
    font-weight: 800;
    font-size: 15px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    th, td {
      text-align: center;
      border: 1px solid #999;
    }
  }
</style>

<template>
  <div>
    <div class="title">
      <span>{{data.type}}</span>
    </div>
    <div v-for="(prescription, i) in data.prescriptionData" :key="prescription.id + '-' + i">
      <div>
        组{{i + 1}}:
        起始日期: <span>{{prescription.startTime}}</span>
        用法: <span>{{prescription.instructions}}</span>
        用药频率: <span>{{prescription.usageFrequency}}</span>
        连续天数: <span>{{prescription.dayNum}}</span>
        滴速: <span>{{prescription.dripSpeed}}</span>
      </div>
      <table>
        <tr>
          <th>药品名称/规格</th>
          <th>开药量</th>
          <th>单次剂量</th>
          <th>是否皮试</th>
        </tr>
        <tr v-for="(medicine, i) in prescription.medicineData" :key="medicine.id + '-' + i">
          <td>{{medicine.medicineName}}</td>
          <td>{{medicine.volume}}</td>
          <td>{{medicine.timesVolume}}</td>
          <td>{{medicine.skinTest}}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import mixins from './mixins'
export default {
  mixins: [mixins]
}
</script>
